import React, { useCallback, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Checkbox,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  TextField,
  Snackbar,
  Alert,
  FormControlLabel,
  Stack,
  MenuItem,
  Slide,
  Divider,
  IconButton,
  FormControl,
  Pagination,
  Skeleton,
} from "@mui/material";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { useState, useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Box } from "@mui/system";
import { baseUrl } from "../../Utils/serviceRequest";
import { setInventoryFilteredData } from "../../redux/reducers/appReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  Close,
  Details,
  Done,
  Edit,
  FilterAltOff,
  Info,
  SearchOutlined,
} from "@mui/icons-material";
import DraggableFileUploader from "../DraggableComponent/DraggableFileUploader";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import {
  createAssetAPI,
  deleteAsset,
  fetchFilteredPaginatedData,
  fetchPaginatedData,
  fetchSearchedPaginatedData,
} from "../../DatabaseServices/InventoryServices/RegisteredAssetsService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery } from "react-query";
import ViewData from "./ViewData";

//new way to handle dropdowns

const date = new Date(Date.now()).toISOString().substr(0, 10);

const assetHeaders = {
  assetId: "Asset ID",
  assetSlNo: "Asset Sl No",
  assetType: "Asset Type",
  oS: "OS",
  processor: "Processor",
  ram: "RAM",
  storage: "Storage",
  gen: "Generation",
  model: "Model",
  brand: "Brand",
  comments: "Comment",
  state: "State",
  location: "Location",
  warrantyEndDate: "Warranty End Date",
  procureDate: "Procure Date",
};

const tagNumber = {
  "Bangalore/Laptop/HP": "INC/BANG/HP",
  "Bangalore/Laptop/Dell": "INC/BNGLR/DL",
  "Bangalore/Laptop/Lenovo": "INC/BANG/LT",
  "Bangalore/Laptop/Apple": "INC/BANG/MAC-",
  "Bangalore/Mobile/Apple": "INC/BANG/MOB-",
  "Bangalore/Mobile/Samsung": "INC/BANG/MOB-",
  "Bhubaneswar/Laptop/HP": "INC/BBSR/HP",
  "Bhubaneswar/Laptop/Dell": "INC/BBSR/DL",
  "Bhubaneswar/Laptop/Lenovo": "INC/BBSR/LT",
  "Bhubaneswar/Laptop/Apple": "INC/BBSR/MAC-",
  "Bhubaneswar/Mobile/Apple": "INC/BBSR/MOB-",
  "Bhubaneswar/Mobile/Samsung": "INC/BBSR/MOB-",
};

//dropdown handling constand ends here

/*constant to define filter state*/
const globalFilterState = {
  Location: {
    Bhubaneswar: {
      label: "Bhubaneswar",
      checked: false,
    },
    Bangalore: {
      label: "Bangalore",
      checked: false,
    },
  },
  Type: {
    Laptop: {
      label: "Laptop",
      checked: false,
    },
    Mobile: {
      label: "Mobile",
      checked: false,
    },
  },
  Brand: {
    Apple: {
      label: "Apple",
      checked: false,
    },
    Dell: {
      label: "Dell",
      checked: false,
    },
    HP: {
      label: "HP",
      checked: false,
    },
    Lenovo: {
      label: "Lenovo",
      checked: false,
    },
  },
  Status: {
    isActive: {
      label: "Is Active",
      checked: false,
    },
    isInActive: {
      label: "Is Inactive",
      checked: false,
    },
  },
};

const globalFilterPayload = {
  Location: [],
  Type: [],
  Brand: [],
  isActive: [],
  searchParam: [],
};
/***/

//Add Registered Asset Label configuration for Select Dropdowns

const assetType = ["Laptop", "Mobile"];
const assetBrand = {
  Laptop: ["Lenovo", "HP", "Dell", "Apple"],
  Mobile: ["Apple", "Samsung"],
};
const storageOptions = {
  Laptop: ["128GB", "256GB", "500GB", "512GB", "1TB", "2TB"],
  Mobile: ["32GB", "64GB", "128GB", "256GB"],
};
const ramOptions = {
  Laptop: ["4GB", "8GB", "16GB", "32GB"],
  Mobile: ["8GB", "16GB"],
};

// const inputSize = {
//   style: {
//     height: "5vh",
//     maxHeight: "32px",
//     width: "15em",
//     padding: "2px 10px",
//   },
// };

const inputSize = {
  style: {
    height: "5vh",
    maxHeight: "32px",
    width: "15em",
    padding: "2px 10px",
  },
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 300, // Set your preferred max height
        overflowY: "auto", // Enable vertical scrolling
      },
    },
  },
};

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const PaginatedMuiGridRegisteredAssets = () => {
  const resetTagId = (data) => {
    let key = `${data.location}/${data.assetType}/${data.brand}`;
    let newTag = tagNumber[key];
    return newTag;
  };
  const dispatch = useDispatch();

  const [noOfFiltersChecked, setNoOfFiltersChecked] = useState(0);
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  //state for search
  const [searchInput, setSearchInput] = useState("");
  const onChangeSearch = async (e, tempNoOfFilters, tempPayload) => {
    if (e && e.target.value === "") {
      setSearchInput(e.target.value);
      if (tempNoOfFilters > 0) {
        filteredSearchAPICall();
      } else {
        fetchData();
      }
      return;
    }
    setPageState((prev) => ({ ...prev, isLoading: true }));
    let searchText;
    if (e) {
      searchText = e.target.value;
      setSearchInput(e.target.value);
    } else {
      searchText = searchInput;
    }
    if (tempNoOfFilters > 0 && searchText !== "") {
      console.log(
        "need to implement this feature till then please clear filter"
      );
      filteredSearchAPICall({ ...tempPayload, searchParam: [searchText] });
      return;
    }
    try {
      let res = await axios.get(
        `${baseUrl}/searchAssets/${pageState.pageSize}/${pageState.page}?param=${searchText}`
      );
      if (res.data && res.data.result === "success") {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
          data: res.data.data,
          total: res.data.count,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimizedFn = useCallback(debounce(onChangeSearch), []);

  //End of Label configuration

  /* Validations */

  const handleValidations = (val) => {
    if (EditFormData["baseLocation"].length === 0) {
      // alert(" Base Location cannot be empty");
      setSnackBarMessage("Base Location cannot be empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else if (EditFormData["assetTypeId"].length === 0) {
      // alert(" Asset Type cannot be empty");
      setSnackBarMessage(" Asset Type cannot be empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else if (EditFormData["assetId"].length === 0) {
      // alert(" Invalid Tag Number");
      setSnackBarMessage("Invalid Tag Number");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else if (EditFormData["assetSlNo"].length === 0) {
      // alert(" Product serial number field Cannot be Empty");
      setSnackBarMessage("Product serial number field cannot be empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else if (EditFormData["state"].length === 0) {
      // alert(" Health field cannot be Empty");
      setSnackBarMessage("Health field cannot be Empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else if (EditFormData["model"].length === 0) {
      // alert(" Model field cannot be Empty");
      setSnackBarMessage("Model field cannot be Empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else if (
      (EditFormData["assetType"] === "Mobile" ||
        EditFormData["assetType"] === "Laptop") &&
      EditFormData["storage"].length === 0
    ) {
      // alert(" Storage field cannot be Empty");
      setSnackBarMessage("Storage field cannot be Empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else if (
      (EditFormData["assetType"] === "Mobile" ||
        EditFormData["assetType"] === "Laptop") &&
      EditFormData["ram"].length === 0
    ) {
      setSnackBarMessage("RAM field cannot be Empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else {
      if (val === 0) {
        createAsset();
      } else if (val === 1) {
        updateAsset();
      }
    }
  };

  const downloadFile = async () => {
    const workbook = new Excel.Workbook();
    try {
      const fileName = `REPORT`;
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(`Inventory-Data`);

      worksheet.columns = [
        { header: "Asset Type", key: "assetType" },
        { header: "Location", key: "location" },
        { header: "Product Sl No", key: "assetSlNo" },
        { header: "Brand", key: "brand" },
        { header: "Asset ID", key: "assetId" },
        { header: "Assigned", key: "isActive" },
        { header: "Health", key: "state" },
      ];

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: "center" };
      });

      // loop through data and add each one to worksheet
      pageState.data?.forEach((singleData) => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      // removing worksheet's instance to create new one
      // workbook.removeWorksheet(workSheetName);
    }
  };

  // **master data********************
  //InventoryData is the source for the data sent to the table. data is stored by fetching the data from the database

  const appReducerState = useSelector((state) => state.appReducer);

  //mui Grid code

  const handleDeleteMuiGrid = async () => {
    try {
      let response = await deleteAsset(SelectedAssets);
      console.dir(response);
      if (response.status === 200) {
        fetchData();
        setDeleteModalStatus(false);
        setSelectedAssets([]);
        setSuccessStatus(true);
      }
      if (response.status !== 200) {
        setSelectedAssets([]);
        setDeleteModalStatus(false);
        setFailedStatus(true);
      }
    } catch (error) {
      setSelectedAssets([]);
      setDeleteModalStatus(false);
      setFailedStatus(true);
    }
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 700,
    page: 1,
    pageSize: 50,
  });

  //end of mui grid code

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // const fetchData = async () => {
  //   try {
  //     setPageState((prev) => ({ ...prev, isLoading: true }));
  //     let res = await axios.get(
  //       `${baseUrl}/getAssets/${pageState.pageSize}/${pageState.page}`
  //     );
  //     if (res.status === 200 && res.data.result === "success") {
  //       setLoading(false);
  //       setPageState((prev) => ({
  //         ...prev,
  //         isLoading: false,
  //         data: res.data.data,
  //         total: res.data.count,
  //       }));
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };
  const fetchData = async () => {
    // debugger;
    try {
      // setPageState((prev) => ({ ...prev, isLoading: true }));
      // let res = await axios.get(
      //   `${baseUrl}/getAssets/${pageState.pageSize}/${pageState.page}`
      // );

      // new inventory api test
      let res = await axios.get(
        `${baseUrl}/getAssetsV2`
        // `${baseUrl}/getAssetsV2/${pageState.pageSize}/${pageState.page}`
        // "http://localhost:8080/getAssetsV2/10/1"
      );
      // if (res.status === 200 && res.data.result === "success")
      if (res.status === 200) {
        setLoading(false);
        setData(res.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const processedData = data.map((row) => ({
    ...row,
    employeeName:
      row.employeeName === null || row.employeeName === undefined
        ? " "
        : row.employeeName,
  }));
  // console.log("process data :", processedData);

  const filteredSearchAPICall = async (_payload) => {
    try {
      setPageState((prev) => ({ ...prev, isLoading: true }));
      let payload = _payload ? _payload : { ...filterPayload };
      if (searchInput !== "") {
        payload.searchParam = [searchInput];
      }
      let data = await fetchFilteredPaginatedData(
        pageState.pageSize,
        pageState.page,
        payload
      );
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        data: data?.data ?? [],
        total: data?.count,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (noOfFiltersChecked > 0) {
  //     filteredSearchAPICall();
  //   } else if (searchInput) {
  //     onChangeSearch(noOfFiltersChecked);
  //   } else {
  //     fetchData();
  //   }
  // }, [pageState.page, pageState.pageSize]);

  // ***********all state variables **

  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [AddModalStatus, setAddModalStatus] = useState(false);
  const [detailsModalStatus, setDetailsModalStatus] = useState(false);
  const [SuccessStatus, setSuccessStatus] = useState(false);
  const [FailedStatus, setFailedStatus] = useState(false);
  const [message, setMessage] = useState("failed");
  const [importStatus, setImportStatus] = useState(false);
  const [filterListStatus, setFilterListStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedAssetForDetail, setSelectedAssetForDetail] = useState(null);

  const [filteredOptionList, setFilteredOptionList] = useState([]);

  // new filter states

  const [filterPayload, setFilterPayload] = useState(
    structuredClone(globalFilterPayload)
  );

  const [filterStates, setFilterStates] = useState(
    structuredClone(globalFilterState)
  );

  const clearFilter = () => {
    setFilterStates(structuredClone(globalFilterState));
    setFilterPayload(structuredClone(globalFilterPayload));
    setNoOfFiltersChecked(0);
  };

  const handleOnFilterChange = (e, filterKey, optionKey) => {
    let name = e.target.name;
    let value = e.target.checked;
    let tempFilterStates = { ...filterStates };
    tempFilterStates[filterKey][optionKey].checked = value;
    setFilterStates(tempFilterStates);
    let tempFilterPayload = { ...filterPayload };
    if (filterKey !== "Status") {
      if (value === false) {
        tempFilterPayload[filterKey].splice(
          tempFilterPayload[filterKey].indexOf(optionKey),
          1
        );
      } else {
        tempFilterPayload[filterKey].push(optionKey);
      }
    } else {
      if (
        (tempFilterStates.Status.isActive.checked &&
          tempFilterStates.Status.isInActive.checked) ||
        (!tempFilterStates.Status.isActive.checked &&
          !tempFilterStates.Status.isInActive.checked)
      ) {
        tempFilterPayload["isActive"] = [];
      } else if (
        tempFilterStates.Status.isActive.checked &&
        !tempFilterStates.Status.isInActive.checked
      ) {
        tempFilterPayload["isActive"] = [true];
      } else {
        tempFilterPayload["isActive"] = [false];
      }
    }
    console.log(tempFilterPayload);
    let tempNoOfFilters = 0;
    Object.values(tempFilterPayload).forEach((item, index) => {
      if (index !== 4) tempNoOfFilters += item.length;
    });
    setNoOfFiltersChecked(tempNoOfFilters);
    setFilterPayload(tempFilterPayload);
  };

  //functionality to get filtered data
  const [filteredData, setFilteredData] = useState([]);

  const setFilteredDataFunc = () => {
    const filteredRows = appReducerState.inventoryData.primaryData.data.filter(
      (row) => {
        let selected = true;
        filteredOptionList.forEach((element) => {
          if (element["name"] !== row[element["type"]]) {
            selected = false;
            return;
          }
        });
        return selected;
      }
    );
    setFilteredData(filteredRows);
    dispatch(setInventoryFilteredData(filteredRows));
  };

  //checkbox filtering ends

  const [ReceivedIdFromTable, setReceivedIdFromTable] = useState(null); //data updated from id received by the showDeleteModal functions provided to table component

  //DElETE RELATED OPERATIONS
  const [SelectedAssets, setSelectedAssets] = useState([]);
  const [DeleteMethod, setDeleteMethod] = useState(null);

  const [loc, setLoc] = useState([]);
  const [assetType, setAssetTypeName] = useState([]);
  const [brand, setBrandName] = useState([]);
  const [classification, setClassification] = useState([]);

  //------

  const [EditFormData, setEditFormData] = useState({
    assetId: "",
    // assetId: tagNumber["Bhubaneswar/Laptop/Lenovo"],
    assetSlNo: "",
    // assetType: assetType[0],
    assetType: "",
    assetTypeId: "",
    classification: "",
    oS: "",
    processor: "",
    ram: "",
    storage: "",
    // ram: ramOptions["Laptop"][0],
    // storage: storageOptions["Laptop"][0],
    gen: "",
    model: "",
    brandId: "",
    // brand: assetBrand["Laptop"][0],
    comments: "",
    state: "",
    baseLocation: "",
    // location: "Bhubaneswar",
    warrantyEndDate: "",
    // warrantyEndDate: Date.now(),
    procureDate: Date.now(),
  });

  const getLocation = async () => {
    try {
      let data = await axios.get(
        "https://topas-backend.cherrywork.com/asset-api/getTopasLocations"
      );
      // console.log(data, "data");
      // console.log(data.data.data, "data1");
      if (data) {
        setLoc(data.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAssetTypeName = async () => {
    // debugger;
    try {
      let res = await axios.get(`${baseUrl}/allAssetTypes`);
      // let res = await axios.get("http://localhost:8080/getAssetTypeName");
      console.log(res);
      if (res.data.result === "success") {
        console.log("assetTypeName", res.data.data);
        setAssetTypeName(res.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getAllBrands = async () => {
    try {
      const response = await axios.get(`${baseUrl}/allBrands`);
      console.log("brand name", response.data);
      if (response.data.result === "success") {
        setBrandName(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
      //  setLoading(false)
    }
  };
  const getAllClassification = async () => {
    try {
      const response = await axios.get(`${baseUrl}/allClassifications`);
      console.log("classification name", response.data);
      if (response.data.result === "success") {
        setClassification(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
      //  setLoading(false)
    }
  };

  useEffect(() => {
    getAssetTypeName();
    getAllClassification();
    getAllBrands();
    getLocation();
  }, []);

  // ******all state variables ends**
  // ***********functions to handle update, delete & add data **

  const uploadFile = async (e) => {
    // debugger;
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      //formData.append("sheetName", "Sheet1");
      let data = await axios.post(
        `${baseUrl}/asset/uploadAssetMaster`,
        formData
      );
      if (data.status === 200) setSuccessStatus(true);
      handleClose(e);
      if (data.status !== 200) setFailedStatus(true);
    } catch (error) {
      console.error(error);
      setFailedStatus(true);
    }
  };
  const currentUser = useSelector((state) => state.userReducer.userData);

  const createAsset = async () => {
    // debugger
    let stateData = { ...EditFormData, performedBy: currentUser.employeeId };
    try {
      let data = await createAssetAPI(stateData);
      if (data) {
        handleClose();
      }
      if (data.data.result === "success") {
        fetchData();
        setSuccessStatus(true);
        // setSnackBarMessage(data.data.message);
        // setSnackBarSeverity("success");
        // setSnackBarStatus(true);
      }
      if (data.data.result === "failed") {
        // setMessage(data.data.message);
        // setFailedStatus(true);
        setSnackBarMessage(data.data.message);
        setSnackBarSeverity("error");
        setSnackBarStatus(true);
      }
    } catch (error) {
      console.error(error);
      setFailedStatus(true);
    }
  };

  const updateAsset = async () => {
    let stateData = { ...EditFormData };
    try {
      let data = await axios.put(`${baseUrl}/updateAsset`, stateData);
      if (data) {
        handleClose();
      }
      if (data.status === 200) {
        fetchData();
        // setSuccessStatus(true);
        setSnackBarMessage(data.data.message);
        setSnackBarSeverity("success");
        setSnackBarStatus(true);
      }
      if (data.status !== 200) {
        // setFailedStatus(true);
        setSnackBarMessage(data.data.message);
        setSnackBarSeverity("error");
        setSnackBarStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  let handleOnChange = (e) => {
    // debugger;
    //single function handling both updating the data and adding the data, form data is handled in a single EditFromData state reason for data structure being same
    let value = e.target.value;
    let name = e.target.name;

    // const selectedClassification=classification.find((option) => option.classificationName === value);
    // const classificationId = selectedClassification ? selectedClassification.assetClassificationId : null;

    let stateData = { ...EditFormData };

    if (name === "assetTypeId") {
      if (value !== "Laptop" && value !== "Mobile") {
        stateData["ram"] = "";
        stateData["storage"] = "";
      }
    }

    if (name === "assetTypeId") {
      const selectedAsset = assetType.find(
        (option) => option.assetTypeName === value
      );
      const assetId = selectedAsset ? selectedAsset.assetTypeId : null;
      stateData.assetType = value;
      stateData[name] = assetId;
      // new code testing//
      const classificationName = selectedAsset
        ? selectedAsset.classificationMaster.classificationName
        : null;
      stateData["classification"] = classificationName;
      //end//
    } else if (name === "brandId") {
      const selectedBrand = brand.find((option) => option.brandName === value);
      const brandId = selectedBrand ? selectedBrand.brandId : null;
      stateData[name] = brandId;
    } else {
      stateData[name] = value;
    }

    setEditFormData(stateData);
  };

  // functions to handle display and hidding of modals*******************

  let handleClose = (e) => {
    // handling closing for all modals, and then updates the EditFormData to a blank template to make clear form input values //problem was in switching from edit process to add process.
    setDeleteModalStatus(false);
    setEditModalStatus(false);
    setAddModalStatus(false);
    setEditFormData({
      assetId: "",
      // assetId: tagNumber["Bhubaneswar/Laptop/Lenovo"],
      assetSlNo: "",
      assetType: "",
      assetTypeId: "",
      classification: "",
      // assetType: assetType[0],
      oS: "",
      processor: "",
      ram: ramOptions["Laptop"][0],
      storage: storageOptions["Laptop"][0],
      gen: "",
      model: "",
      brandId: "",
      // brand: assetBrand["Laptop"][0],
      comments: "",
      state: "",
      baseLocation: "",
      // location: "Bhubaneswar",
      warrantyEndDate: "",
      // warrantyEndDate: Date.now(),
      procureDate: Date.now(),
    });
    setImportStatus(false);
  };
  //**********showing delete modal ********/
  let ShowDeleteModalFunc = (receivedIdFromTable, method) => {
    method === "DELETEONE" && setReceivedIdFromTable(receivedIdFromTable);
    method === "DELETEONE" && setDeleteMethod(method);
    setDeleteModalStatus(true);
  };
  //****/

  //**********showing import modal ********/
  let ShowImportModalFunc = () => {
    setImportStatus(true);
  };
  //****/

  //**********showing add modal ********/

  let ShowAddModal = () => {
    setAddModalStatus(true);
  };

  //**********showing edit modal ********/

  let ShowEditModalFuncNew = (rowData) => {
    let func = () => {
      setEditFormData({ ...EditFormData, ...rowData });
      setEditModalStatus(true);
    };
    func();
  };
  let getNextBatchOfData = () => {
    fetchData();
  };

  let dialogTheme = createTheme({
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingTop: "10px",
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            paddingTop: "10px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "10px",
          },
        },
      },
    },
  });
  if (SuccessStatus | FailedStatus) {
    setTimeout(() => {
      setFailedStatus(false);
      setSuccessStatus(false);
    }, 3000);
  }

  //end of poc

  return (
    <>
      <Stack style={{ position: "relative" }}>
        <Box
          sx={{ display: "flex" }}
          style={{
            position: "absolute",
            top: "-97px",
            // top: "-44px",
            right: "0",
            // width: "11%",
            width: "100%",
          }}
        >
          <Box
            // sx={{
            //   flexGrow: "1",
            //   display: "flex",
            //   justifyContent: "flex-end",
            //   gap: "2%",
            //   alignItems: "center",
            // }}
            sx={{
              flexGrow: "1",
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "2%",
            }}
          >
            {/* <FormControl
              sx={{
                width: "30%",
                height: "5vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                placeholder="Search by asset slno/id"
                inputProps={inputSize}
                InputProps={{
                  endAdornment: <SearchOutlined color="disabled" />,
                }}
                onChange={(e) =>
                  optimizedFn(e, noOfFiltersChecked, filterPayload)
                }
              />
            </FormControl> */}
            {/* <Button
              disabled={SelectedAssets.length === 0}
              variant="contained"
              endIcon={
                <DeleteIcon
                  sx={{ color: SelectedAssets.length > 0 ? "red" : "grey" }}
                />
              }
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={ShowDeleteModalFunc}
            >
              {" "}
              Delete
            </Button>
            <Box>
              <Button
                variant="contained"
                endIcon={
                  !filterListStatus ? <FilterAltIcon /> : <FilterAltOff />
                }
                disabled={filterListStatus}
                sx={{
                  border: "1px solid #F1F5FE",
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  height: "max-content",
                  "&:hover": {
                    backgroundColor: "#F1F5FE",
                    color: "#0288d1",
                    border: "1px solid #0288d1",
                    boxShadow: "1px #000000",
                  },
                }}
                onClick={() => {
                  setFilterListStatus((prev) => !prev);
                }}
              >
                {" "}
                {"Filter"}
              </Button>
            </Box>
            <Button
              variant="contained"
              endIcon={<FileDownloadIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={() => {
                downloadFile();
              }}
            >
              Export
            </Button> */}
            {/* <Button
              variant="contained"
              endIcon={<UploadFileIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={ShowImportModalFunc}
            >
              {" "}
              Import
            </Button> */}
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={ShowAddModal}
            >
              Add
            </Button>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" style={{ padding: "0" }}>
          {/* new table */}
          <ViewData
            data={processedData}
            showAction={true}
            loading={loading}
            fetchData={fetchData}
          />

          {/* <Slide
            direction="left"
            in={filterListStatus}
            mountOnEnter
            unmountOnExit
          >
            <Paper
              style={{ backgroundColor: "#f1f5fe", height: "78vh" }}
              sx={{ p: "0em 0.2em" }}
            >
              <Box sx={{ height: "10%" }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    fontSize={18}
                    fontWeight="bold"
                    p={"0.1em 0em"}
                    color="#0288d1"
                  >
                    Add Filters
                  </Typography>
                  <IconButton onClick={clearFilter}>
                    <FilterAltOff />
                  </IconButton>
                </Box>
                <Divider />
              </Box>
              <Box
                sx={{
                  height: "88%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {Object.keys(filterStates).map((filterKey) => {
                    return (
                      <Box sx={{ p: "0.2em" }}>
                        <Typography
                          variant="h4"
                          fontSize={16}
                          fontWeight="bold"
                          sx={{ display: "block" }}
                        >
                          {filterKey}
                        </Typography>
                        <Box pl={"0.5em"}>
                          {Object.keys(filterStates[filterKey]).map(
                            (optionKeys) => {
                              return (
                                <FormControlLabel
                                  label={
                                    filterStates?.[filterKey]?.[optionKeys]
                                      ?.label ?? ""
                                  }
                                  control={
                                    <Checkbox
                                      name={optionKeys}
                                      checked={
                                        filterStates?.[filterKey]?.[optionKeys]
                                          ?.checked ?? false
                                      }
                                      onChange={(e) =>
                                        handleOnFilterChange(
                                          e,
                                          filterKey,
                                          optionKeys
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 18 },
                                      }}
                                    />
                                  }
                                />
                              );
                            }
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <ButtonGroup>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        if (noOfFiltersChecked > 0 || searchInput !== "") {
                          filteredSearchAPICall();
                        } else {
                          fetchData();
                        }
                        setFilterListStatus(false);
                      }}
                    >
                      Apply
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => setFilterListStatus(false)}
                    >
                      Close
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Paper>
          </Slide> */}
        </Box>
        {/* </Collapse> */}
      </Stack>
      {/***** SNACKBAR COMPONENT * */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={SuccessStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        style={{ marginTop: "60px" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          SUCCESS!!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={FailedStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        style={{ marginTop: "60px" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        style={{ marginTop: "60px" }}
        autoHideDuration={4000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>

      {/* *******************  */}

      {/* *modal components***  */}

      <ThemeProvider theme={dialogTheme}>
        {/* *modal import***  */}

        <Dialog open={importStatus}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <Typography variant="h6">Select File</Typography>
          </DialogTitle>
          <DialogContent sx={{ padding: "10px" }} className="wbScroll">
            <DraggableFileUploader
              file={selectedFile}
              setFile={setSelectedFile}
              fileTypes={["xlsx"]}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => uploadFile(e)}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Upload
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* *modal DELETE***  */}

        {/* <Dialog open={deleteModalStatus}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <ErrorOutlineIcon sx={{ color: "#FF9A22", fontSize: "2.3rem" }} />
            <Typography variant="h6">Are you sure?</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>
              <span>Are you sure you want to delete this record?</span>
              <br />
              <span>This process cannot be undone.</span>
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={() => handleDeleteMuiGrid()}
              variant="contained"
              sx={{
                backgroundColor: "#CF0100",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#B70100",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                if (SelectedAssets.length === 1) {
                  setSelectedAssets([]);
                }
                setDeleteModalStatus(false);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
        {/* *modal EDIT***  */}

        {/* <Dialog open={editModalStatus} sx={{}}>
          <DialogContent sx={{ padding: "10px" }} className="wbScroll">
            <Stack>
              <Grid container spacing={8} rowSpacing={3}>
                <GridDropDownItem
                  label={"Location"}
                  disabled={true}
                  onChang={handleOnChange}
                  name={"location"}
                  defaultValue={EditFormData.location}
                  menuItems={{
                    Bhubaneswar: "Bhubaneswar",
                    Bangalore: "Bangalore",
                  }}
                />
                <GridDropDownItem
                  label={"Brand"}
                  disabled={true}
                  onChang={handleOnChange}
                  name={"make"}
                  defaultValue={EditFormData.brand}
                  menuItems={{
                    Lenovo: "Lenovo",
                    HP: "HP",
                    Dell: "Dell",
                    Apple: "Apple",
                    Samsung: "Samsung",
                  }}
                />
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Tag Number<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={true}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetId"
                    InputProps={{
                      style: { ...inputSize.style, paddingLeft: "20px" },
                    }}
                    value={EditFormData.assetId}
                    onChange={handleOnChange}
                  />
                </Grid>
                <GridDropDownItem
                  label={"Type"}
                  disabled={true}
                  onChang={handleOnChange}
                  name={"type"}
                  defaultValue={EditFormData.assetType}
                  menuItems={{
                    Laptop: "Laptop",
                    Mobile: "Mobile",
                  }}
                />
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Product Serial Number<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetSlNo"
                    value={EditFormData.assetSlNo}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Health<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="state"
                    value={EditFormData.state}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
              <Box variant="button" sx={{ margin: "20px 0px" }}>
                <Typography sx={{ color: "#1D1D11" }}>
                  {" "}
                  Configurations :-
                </Typography>
              </Box>
              <Grid container spacing={8} rowSpacing={3}>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Processor<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="processor"
                    value={EditFormData.processor}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Storage<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="storage"
                    value={EditFormData.storage}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Generation<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="gen"
                    value={EditFormData.gen}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    RAM<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="ram"
                    value={EditFormData.ram}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    OS<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="oS"
                    value={EditFormData.oS}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Model<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="model"
                    value={EditFormData.model}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleValidations(1)}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}

        {/* *modal ADD NEW DATA***  */}
        <Dialog open={AddModalStatus} sx={{ padding: "20px" }}>
          <DialogContent sx={{ padding: "10px" }} className="wbScroll">
            <Stack>
              <Grid container spacing={8} rowSpacing={3}>
                {/* <Grid item xs={12} sx={{ maxWidth: "100%" }}> */}
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242", width: "100%" }}>
                    Base Location<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      select
                      required
                      // disabled={false}

                      // sx={{ width: "100%", }}
                      margin="normal"
                      SelectProps={inputSize}
                      // InputProps={inputSize}

                      id="outlined-basic"
                      variant="outlined"
                      name="baseLocation"
                      value={EditFormData.baseLocation}
                      onChange={handleOnChange}
                    >
                      {loc.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242", width: "100%" }}>
                    Asset Type<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      select
                      required
                      // disabled={false}

                      // sx={{ width: "100%", }}
                      margin="normal"
                      SelectProps={inputSize}
                      // InputProps={inputSize}

                      id="outlined-basic"
                      variant="outlined"
                      name="assetTypeId"
                      // value={EditFormData.assetType}
                      value={
                        assetType.find(
                          (option) =>
                            option.assetTypeId === EditFormData.assetTypeId
                        )?.assetTypeName || ""
                      }
                      onChange={handleOnChange}
                    >
                      {assetType
                        .filter((item) => item.isActive === true)
                        .map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.assetTypeName}>
                              {option.assetTypeName}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Location<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    required
                    select
                    sx={{ width: "max-content" }}
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    defaultValue="Bhubaneswar"
                    variant="outlined"
                    name="location"
                    value={EditFormData.location}
                    onChange={handleOnChange}
                  >
                    <MenuItem value={"Bhubaneswar"}>Bhubaneswar</MenuItem>
                    <MenuItem value={"Bangalore"}>Bangalore</MenuItem>
                  </TextField>
                </Grid> */}
                {/* <GridDropDownItem
                  label={"Type"}
                  onChang={handleOnChange}
                  name={"type"}
                  defaultValue={EditFormData.assetType}
                  menuItems={{
                    Laptop: "Laptop",
                    Mobile: "Mobile",
                  }}
                /> */}

                {/* <Grid item xs={6}>
                  <Typography sx={{ color: "#424242", width: "100%" }}>
                    Classification<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      select
                      required
                      // disabled={false}

                      // sx={{ width: "100%", }}
                      margin="normal"
                      SelectProps={inputSize}
                      // InputProps={inputSize}

                      id="outlined-basic"
                      variant="outlined"
                      name="classification"
                      value={EditFormData.classification}
                      onChange={handleOnChange}
                    >
                      {classification.map((option, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={option.classificationName}
                          >
                            {option.classificationName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                </Grid> */}

                {/*testing code  */}
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Classification<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={true}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="classification"
                    autoComplete="off"
                    InputProps={{
                      // startAdornment: <span>{prefix}</span>,
                      style: { ...inputSize.style, paddingLeft: "20px" },
                    }}
                    // value={
                    //   assetType.find(
                    //     (asset) => asset.assetType === EditFormData.assetType
                    //   )?.classificationMaster.classificationName || ""
                    // }
                    value={EditFormData.classification}
                    onChange={handleOnChange}
                  />
                </Grid>
                {/* end */}

                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242", width: "100%" }}>
                    Brand<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      select
                      required
                      // disabled={false}

                      // sx={{ width: "100%", }}
                      margin="normal"
                      SelectProps={inputSize}
                      // InputProps={inputSize}

                      id="outlined-basic"
                      variant="outlined"
                      name="brandId"
                      value={
                        brand.find(
                          (option) => option.brandId === EditFormData.brandId
                        )?.brandName || ""
                      }
                      onChange={handleOnChange}
                    >
                      {brand
                        .filter((item) => item.isActive === true)
                        .map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.brandName}>
                              {option.brandName}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
                {/* <GridDropDownItem
                  label={"Brand"}
                  onChang={handleOnChange}
                  name={"make"}
                  defaultValue={EditFormData.brand}
                  menuItems={{
                    Lenovo: "Lenovo",
                    HP: "HP",
                    Dell: "Dell",
                    Apple: "Apple",
                    Samsung: "Samsung",
                  }}
                /> */}
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Tag Number<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetId"
                    autoComplete="off"
                    InputProps={{
                      // startAdornment: <span>{prefix}</span>,
                      style: { ...inputSize.style, paddingLeft: "20px" },
                    }}
                    value={EditFormData.assetId}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Product Serial Number
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    required
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetSlNo"
                    autoComplete="off"
                    value={EditFormData.assetSlNo}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Health<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="state"
                    autoComplete="off"
                    value={EditFormData.state}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
              <Box variant="button" sx={{ margin: "20px 0px" }}>
                <Typography sx={{ color: "#1D1D11" }}>
                  {" "}
                  Configurations :-{" "}
                </Typography>
              </Box>
              <Grid container spacing={8} rowSpacing={3}>
                <GridDateInput
                  xs={6}
                  label="Procure Date"
                  name="procureDate"
                  value={EditFormData.procureDate}
                  onChange={(e) => handleOnChange(e)}
                />
                <GridDateInput
                  xs={6}
                  label="Warranty End Date"
                  name="warrantyEndDate"
                  value={EditFormData.warrantyEndDate}
                  onChange={(e) => handleOnChange(e)}
                />
                {EditFormData.assetType !== "Laptop" &&
                EditFormData.assetType !== "Mobile" ? null : (
                  // {EditFormData.assetType === "Mobile" ||
                  // EditFormData.assetType === "Mouse" ||
                  // EditFormData.assetType === "Monitors" ||
                  // EditFormData.assetType === "Cctv" ||
                  // EditFormData.assetType === "Keyboard" ||
                  // EditFormData.assetType === "Dongle" ||
                  // EditFormData.assetType === "Wireless Keyboard Mouse" ||
                  // EditFormData.assetType === "Wireless Mouse" ? null : (
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>Processor</Typography>
                    <TextField
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="processor"
                      autoComplete="off"
                      value={EditFormData.processor}
                      onChange={handleOnChange}
                    />
                  </Grid>
                )}

                {/* {EditFormData.assetType === "Dongle" ||
                EditFormData.assetType === "Mouse" ||
                EditFormData.assetType === "Monitors" ||
                EditFormData.assetType === "Cctv" ||
                EditFormData.assetType === "Keyboard" ||
                EditFormData.assetType === "Wireless Keyboard Mouse" ||
                EditFormData.assetType === "Wireless Mouse" ? null : ( */}
                {EditFormData.assetType !== "Laptop" &&
                EditFormData.assetType !== "Mobile" ? null : (
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      Storage<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      select
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="storage"
                      value={EditFormData.storage}
                      onChange={handleOnChange}
                    >
                      {/* {storageOptions[EditFormData.assetType]?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))} */}
                      {EditFormData.assetType === "Mobile"
                        ? storageOptions.Mobile.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : storageOptions.Laptop.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                    </TextField>
                  </Grid>
                )}

                {/* {EditFormData.assetType === "Mobile" ||
                EditFormData.assetType === "Mouse" ||
                EditFormData.assetType === "Monitors" ||
                EditFormData.assetType === "Cctv" ||
                EditFormData.assetType === "Keyboard" ||
                EditFormData.assetType === "Dongle" ||
                EditFormData.assetType === "Wireless Keyboard Mouse" ||
                EditFormData.assetType === "Wireless Mouse" ? null : ( */}
                {EditFormData.assetType !== "Laptop" ? null : (
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      Generation
                    </Typography>
                    <TextField
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="gen"
                      autoComplete="off"
                      value={EditFormData.gen}
                      onChange={handleOnChange}
                    />
                  </Grid>
                )}

                {/* {EditFormData.assetType === "Dongle" ||
                EditFormData.assetType === "Mouse" ||
                EditFormData.assetType === "Monitors" ||
                EditFormData.assetType === "Cctv" ||
                EditFormData.assetType === "Keyboard" ||
                EditFormData.assetType === "Wireless Keyboard Mouse" ||
                EditFormData.assetType === "Wireless Mouse" ? null : ( */}
                {EditFormData.assetType !== "Laptop" &&
                EditFormData.assetType !== "Mobile" ? null : (
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      RAM<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      select
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="ram"
                      value={EditFormData.ram}
                      onChange={handleOnChange}
                    >
                      {/* {ramOptions[EditFormData.assetType]?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))} */}
                      {EditFormData.assetType === "Mobile"
                        ? ramOptions.Mobile.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : ramOptions.Laptop.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                    </TextField>
                  </Grid>
                )}
                {/* {EditFormData.assetType === "Dongle" ||
                EditFormData.assetType === "Mouse" ||
                EditFormData.assetType === "Monitors" ||
                EditFormData.assetType === "Cctv" ||
                EditFormData.assetType === "Keyboard" ||
                EditFormData.assetType === "Wireless Keyboard Mouse" ||
                EditFormData.assetType === "Wireless Mouse" ? null : ( */}
                {EditFormData.assetType !== "Laptop" &&
                EditFormData.assetType !== "Mobile" ? null : (
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>OS</Typography>
                    <TextField
                      // disabled
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="oS"
                      autoComplete="off"
                      value={EditFormData.oS}
                      onChange={handleOnChange}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Model<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="model"
                    autoComplete="off"
                    value={EditFormData.model}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              onClick={() => handleValidations(0)}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* ***  */}

        {/* Modal to show details of Asset */}
        {/* <Dialog open={detailsModalStatus}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <Info sx={{ color: "#FF9A22", fontSize: "2.3rem" }} />
          </DialogTitle>
          <DialogContent className="wbScroll">
            <Grid
              container
              xs={12}
              alignItems="start"
              justifyContent={"center"}
            >
              {selectedAssetForDetail &&
                Object?.keys(selectedAssetForDetail)?.map((key) => {
                  let value = selectedAssetForDetail[key];
                  if (
                    assetHeaders[key] === "Warranty End Date" ||
                    assetHeaders[key] === "Procure Date"
                  ) {
                    if (value) {
                      var tzoffset = new Date().getTimezoneOffset() * 60000;
                      value = new Date(Number(value - tzoffset))
                        .toISOString()
                        .substring(0, 10);
                    }
                  }
                  return (
                    key !== "isActive" && (
                      <Grid
                        item
                        key={key}
                        xs={3.2}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {assetHeaders[key]}
                        </Typography>
                        <Typography>{`${value ?? "-"}`}</Typography>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={() => {
                setSelectedAssetForDetail(null);
                setDetailsModalStatus(false);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
        {/* *** */}
      </ThemeProvider>
      {/* ***  */}
    </>
  );
};

export default PaginatedMuiGridRegisteredAssets;

const dateInputSize = {
  style: {
    height: "5vh",
    maxHeight: "32px",
    width: "15em",
    padding: "2px 10px",
    paddingLeft: "0px",
    marginTop: "16px",
    marginBottom: "8px",
  },
  disabled: true,
};

const GridDateInput = ({
  sm = 6,
  xs = 6,
  label = "",
  onChange = null,
  value = date,
  name = "",
}) => {
  return (
    <Grid item sm={sm} xs={xs}>
      <Typography sx={{ color: "#424242" }}>
        {label}
        {!name === "warrantyEndDate" ? (
          <span style={{ color: "red" }}>*</span>
        ) : null}
        {/* <span style={{ color: "red" }}>*</span> */}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          InputProps={dateInputSize}
          value={value}
          name={name}
          onChange={(e) => onChange({ target: { value: e, name } })}
          renderInput={(params) => (
            <FormControl sx={{ width: "100%" }}>
              <TextField {...params} />
            </FormControl>
          )}
        />
      </LocalizationProvider>
    </Grid>
  );
};

const GridDropDownItem = ({
  xs = 6,
  label,
  disabled = false,
  onChange,
  name,
  defaultValue,
  menuItems = {},
}) => {
  return (
    <Grid item xs={6}>
      <Typography sx={{ color: "#424242" }}>
        {label}
        {!name === "make" ? <span style={{ color: "red" }}>*</span> : null}
        {/* <span style={{ color: "red" }}>*</span> */}
      </Typography>
      <TextField
        select
        disabled={disabled}
        sx={{ width: "max-content" }}
        SelectProps={inputSize}
        margin="normal"
        id="outlined-basic"
        variant="outlined"
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {Object.keys(menuItems).map((key) => {
          return <MenuItem value={`${key}`}>{menuItems[key]}</MenuItem>;
        })}
      </TextField>
    </Grid>
  );
};
