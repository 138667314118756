import { Close, Done, Edit, Info, SearchOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  ButtonGroup,
  FormControl,
  IconButton,
  Pagination,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { baseUrl } from "../../Utils/serviceRequest";
import { fetchFilteredPaginatedData } from "../../DatabaseServices/InventoryServices/RegisteredAssetsService";
import ViewData from "./ViewData";

function GetAssets({ classificationName }) {
  // let AssetType = { classificationName };
  console.log("1", classificationName);

  ///////////////////new code testing start here//////////////////

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    // debugger
    try {
      // setPageState((prev) => ({ ...prev, isLoading: true }));
      let res = await axios.get(
        // `${baseUrl}/getAssetsV2/${pageState.pageSize}/${pageState.page}?assetType=${assetType}`
        `${baseUrl}/getAssetsV2?classificationName=${classificationName}`
      );
      console.warn(res);
      // if (res.status === 200 && res.data.result === "success")
      if (res.status === 200) {
        setData(res.data.data);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const processedData = data.map((row) => ({
    ...row,
    employeeName:
      row.employeeName === null || row.employeeName === undefined
        ? " "
        : row.employeeName,
  }));

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("new data 2", data);
    // console.log("new data kjhgf", data.data);

    //console.log(assetData[0].model);
  }, [data]);

  return (
    <Stack style={{ position: "relative" }}>
      {/* testing material react table */}

      <ViewData
        data={processedData}
        showAction={true}
        loading={loading}
        fetchData={fetchData}
      />
    </Stack>
  );
}

export default GetAssets;
