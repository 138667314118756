import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  createTheme,
  Divider,
  InputLabel,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Autocomplete,
  ListItem,
  ListItemText,
  Grid,
  ThemeProvider,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { SearchOutlined } from "@mui/icons-material";
import { baseUrl } from "../../Utils/serviceRequest";
import axios from "axios";
import AutoCompleteSearch from "../SearchComponent/AutoCompleteSearch";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const inputSize = {
  style: {
    height: "4vh",
    maxHeight: "32px",
    // width: "13.55em",
    padding: "2px 10px",
  },
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 300, // Set your preferred max height
        overflowY: "auto", // Enable vertical scrolling
      },
    },
  },
};
const inputSizeDesc = {
  style: {
    height: "15vh",
    maxHeight: "15vh",
  },
};

let dateTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "4vh",
          maxHeight: "32px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: "4vh",
          maxHeight: "32px",
          padding: "2px 10px",
        },
      },
    },
  },
});

const dateInputSize = {
  style: {
    height: "4vh",
    maxHeight: "32px",
    padding: "2px 10px",
    paddingLeft: "0px",
  },
  disabled: true,
};

// const date = new Date(Date.now()).toISOString().substr(0, 10);
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
const day = String(currentDate.getDate()).padStart(2, "0");

const dateString = `${year}-${month}-${day}`;
const date = new Date(dateString);

const AssetReturnForm = () => {
  //snackbar state
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  //loading state
  const [isLoading, setIsLoading] = useState(false);
  const reasonList = [
    "Employee exits",
    "Laptop Replacement / Upgraded",
    "Damaged",
  ];
  const [status, setStatus] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");

  const [returnFormData, setReturnFormData] = useState({
    allocationId: "",
    assetId: "",
    userId: "",
    assetReturnedDate: date,
    reason: selectedReason,
  });

  const currentUser = useSelector((state) => state.userReducer.userData);
  // const returnedTo = `${currentUser?.employeeId ?? ""}-${currentUser?.name ?? ""}`

  const [userSearchData, setUserSearchData] = useState([]);

  const [userSelected, setUserSelected] = useState(null);

  const [baseLocation, setBaseLocation] = useState("");
  // const [currentLocation, setCurrentLocation] = useState("");
  const [returnLocation, setReturnLocation] = useState("");
  const [location, setLocation] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  ////location api///

  useEffect(() => {
    // const getStatus = async ()=>{
    //   try {
    //     let data = await await axios.get(
    //       `${baseUrl}/getStatus`
    //     );
    //     console.log(data, "data");
    //     console.log(data.data.data, "data");
    //     if (data) {
    //       setStatus(data.data.data);
    //     }
    //   } catch (error) {}
    // }

    const getLocation = async () => {
      try {
        let data = await await axios.get(
          "https://topas-backend.cherrywork.com/asset-api/getTopasLocations"
        );
        // console.log(data, "data");
        // console.log(data.data.data, "data");
        if (data) {
          setLocation(data.data.data);
        }
      } catch (error) {}
    };

    getLocation();
    // getStatus()
  }, []);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = async (value) => {
    try {
      let response =
        await // await axios.get(`${baseUrl}/getAssetAllocationDistinctUserId/${value}`)
        await axios.get(`${baseUrl}/getDetailsByNameOrEmail?name=${value}`);
      console.log("status response", response);

      if (response.status !== 200 || value === "") {
        setUserSelected(null);
        setSelectedReason("");
        setReturnLocation("");
        setReturnFormData({
          assetAllocationId: "",
          assetId: "",
          userId: "",
          assetReturnedDate: date,
          reason: selectedReason,
        });
      } else if (response.status === 200) {
        setUserSearchData(response.data);
      }
    } catch (error) {
      console.error(error.message);
      setUserSelected(null);
      setReturnLocation("");
      setReturnFormData({
        assetAllocationId: "",
        assetId: "",
        userId: "",
        assetReturnedDate: date,
        reason: selectedReason,
      });
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const _returnAsset = async () => {
    if (returnFormData.reason === "" || returnFormData.reason === null) {
      setSnackBarSeverity("error");
      setSnackBarMessage("reason is empty");
      setSnackBarStatus(true);
    } else {
      console.log("response12", returnFormData);
      try {
        setIsLoading(true);
        let res = await await axios.put(`${baseUrl}/returnAsset`, {
          ...returnFormData,
          // returnedBy: returnedTo,
          returnedBy: currentUser?.employeeId ?? "",
          returnLocation: returnLocation,

          // currentLocation: currentLocation,
          // baseLocation: baseLocation,
        });
        if (res.status === 200) {
          console.log("response", res);
          console.log("response data", res.data);
          console.log("response config data", res.config.data);
          setReturnFormData({
            assetAllocationId: "",
            assetId: "",
            userId: "",
            assetReturnedDate: date,
          });
          setIsLoading(false);
          setUserSelected(null);
          setSnackBarMessage("Asset Returned");
          setSnackBarSeverity("success");
          setSnackBarStatus(true);
          setBaseLocation("");
          // setCurrentLocation("");
          setReturnLocation("");
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setSnackBarMessage("Oops! Something Went Wrong");
        setSnackBarSeverity("success");
        setSnackBarStatus(true);
      }
    }
  };

  const fetchUserAssetAllocation = async (userId) => {
    try {
      let data = (
        await axios.get(`${baseUrl}/getAssetAllocationByUserId/${userId}`)
      ).data;
      if (data) {
        // console.log(data);
        let temp = data?.filter((item) => item?.assetReturnedDate === null);
        console.log("temp", temp);
        setUserSelected(temp);
        setReturnFormData({
          assetAllocationId: data[0].assetAllocationId,
          assetId: data[0].assetId,
          userId: data[0].userId,
          assetReturnedDate: date,
          reason: selectedReason,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box style={{ padding: "0.8em 2em" }}>
        {/* <AutoCompleteSearch
          autoCompleteonChange={(val) => fetchUserAssetAllocation(val)}
          textOnChange={(val) => optimizedFn(val)}
          options={userSearchData}
          placeholder={"Search for User"}
        /> */}

        <Autocomplete
          disableClearable
          sx={{ height: "70px", width: "240px" }}
          value={selectedValue}
          onChange={(event, value) => {
            setSelectedValue(value);
            if (value) {
              fetchUserAssetAllocation(value.employeeId);
            }
          }}
          onInputChange={(event, value) => {
            setInputValue(value);
            optimizedFn(value);
            if (value === "") {
              setSelectedValue(null); // Clear the selected value when input is cleared
            }
          }}
          inputValue={inputValue}
          // onChange={(val) => fetchUserAssetAllocation(val.employeeId)}
          // onChange={(event, value) => {
          //   if (value) {
          //     fetchUserAssetAllocation(value.employeeId);
          //   }
          // }}
          //  onInputChange={(event, value) => optimizedFn(value)}
          options={userSearchData}
          getOptionLabel={(option) =>
            `${option.employeeId} ${option.firstName} ${option.lastName}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for User"
              variant="outlined"
              margin="dense"
            />
          )}
        />
      </Box>
      <ThemeProvider theme={dateTheme}>
        <Grid
          container
          alignItems="center"
          rowSpacing={2}
          columnSpacing={12}
          sx={{ p: "0em 2em" }}
        >
          <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Name
            </InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled
                inputProps={inputSize}
                value={`${userSelected?.[0]?.userName ?? ""}`}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Employee ID
            </InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled
                inputProps={inputSize}
                value={`${userSelected?.[0]?.userId ?? ""}`}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Email ID
            </InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled
                inputProps={inputSize}
                value={`${userSelected?.[0]?.emailId ?? ""}`}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Select Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                InputProps={dateInputSize}
                value={returnFormData.assetReturnedDate}
                onChange={(newValue) => {
                  console.log("date date", newValue);
                  const timestamp = new Date(newValue).getTime(); // Get Unix timestamp
                  const date = new Date(timestamp); // Create Date object from timestamp
                  const formattedDate = `${date.getFullYear()}-${
                    date.getMonth() + 1
                  }-${date.getDate()}`;
                  console.log("return date", formattedDate);
                  setReturnFormData((prev) => {
                    return {
                      ...prev,
                      assetReturnedDate: timestamp ? timestamp : null, // Use timestamp directly
                      // assetReturnedDate: `${
                      //   Date.parse(newValue)
                      //     ? newValue.toISOString().substr(0, 10)
                      //     : { date }
                      // }`,
                    };
                  });
                }}
                renderInput={(params) => (
                  <FormControl sx={{ width: "100%" }}>
                    <TextField disabled {...params} />
                  </FormControl>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Select Asset
            </InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <Select
                defaultValue={returnFormData.assetId}
                value={returnFormData.assetId}
              >
                {userSelected?.map((item) => {
                  return (
                    <MenuItem value={item.assetId}>
                      <ListItem
                        sx={{ padding: "0" }}
                        onClick={() =>
                          setReturnFormData((prev) => {
                            return {
                              ...prev,
                              assetId: item["assetId"],
                              assetAllocationId: item["assetAllocationId"],
                            };
                          })
                        }
                      >
                        <ListItemText primary={`${item.assetId}`} />
                      </ListItem>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Reason
            </InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <Select
                name="reason"
                value={selectedReason}
                onChange={(e) => {
                  setSelectedReason(e.target.value);
                  setReturnFormData((prev) => {
                    return { ...prev, reason: e.target.value };
                  });
                }}
                // SelectDisplayProps={inputSize}
                sx={{ width: "100%" }}
              >
                {reasonList?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Reason 
            </InputLabel>

          

            <FormControl sx={{ width: "100%" }}>
              <TextField
                select
                disabled={false}
                name="reason"
                sx={{ width: "100%", marginTop: "0px" }}
                SelectProps={inputSize}
                id="outlined-basic"
                variant="outlined"
                // value={status}
                // onChange={(e) => {
                //   setStatus(e.target.value);
                // }}
                value={selectedReason}
                onChange={(e) => {
                  
                  setSelectedReason(e.target.value);
                  setReturnFormData((prev) => {
                    return { ...prev, reason: e.target.value.statusName };
                  });
                }}
                
              >
                {status.map((option) => {
                  return (
                    <MenuItem key={option.label} value={option}>
                      {option.statusName}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

          
          </Grid> */}

          <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Returned To
            </InputLabel>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled
                inputProps={inputSize}
                value={currentUser?.employeeId ?? ""}
              />
            </FormControl>
          </Grid>

          {/* new changes */}

          <Grid item sm={3} xs={12}>
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Return Location
            </InputLabel>

            {/* <FormControl sx={{ width: "100%" }}>
              <Select
                name="currentLocation"
                value={currentLocation}
                onChange={(e) => {
                  setCurrentLocation(e.target.value);
                }}
                // SelectDisplayProps={inputSize}
                sx={{ width: "100%" }}
              >
                {location?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl sx={{ width: "100%" }}>
              <TextField
                select
                disabled={false}
                sx={{ width: "100%", marginTop: "0px" }}
                SelectProps={inputSize}
                id="outlined-basic"
                variant="outlined"
                value={returnLocation}
                onChange={(e) => {
                  setReturnLocation(e.target.value);
                }}
              >
                {location.map((option) => {
                  return (
                    <MenuItem key={option.label} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

            {/* <TextField
              select
              disabled={false}
              sx={{ width: "max-content", marginTop: "0px" }}
              SelectProps={inputSize}
              margin="normal"
              id="outlined-basic"
              variant="outlined"
              value={currentLocation}
              onChange={(e) => {
                setCurrentLocation(e.target.value);
              }}
            >
              {location.map((option) => {
                return (
                  <MenuItem key={option.label} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField> */}
          </Grid>

          {/* <Grid item sm={3} xs={12}>
          
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Base Location
            </InputLabel>

            <TextField
              select
              disabled={false}
              sx={{ width: "max-content", marginTop: "0px" }}
              SelectProps={inputSize}
              margin="normal"
              id="outlined-basic"
              variant="outlined"
              value={baseLocation}
              onChange={(e) => {
                setBaseLocation(e.target.value);
              }}
            >
              {location.map((option) => {
                return (
                  <MenuItem key={option.label} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>

          </Grid> */}

          {/* end */}
        </Grid>
      </ThemeProvider>
      <Box style={{ padding: "0.8em 2em" }}>
        <InputLabel
          sx={{
            color: "#424242",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            letterSpacing: "0.005em",
            marginTop: "1%",
          }}
        >
          Description
        </InputLabel>
        <FormControl sx={{ width: "90%", height: "25vh" }}>
          <TextField
            multiline
            rows={25}
            variant="outlined"
            inputProps={inputSizeDesc}
          />
        </FormControl>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button variant="contained" color="info" onClick={() => _returnAsset()}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AssetReturnForm;
