import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  Menu,
  Typography,
  MenuItem,
  Grid,
  Stack,
  Alert,
  Snackbar,
  DialogActions,
  TextField,
  DialogContent,
  Dialog,
  FormControl,
  DialogTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";
import { Close, Done } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { deleteAsset } from "../../DatabaseServices/InventoryServices/RegisteredAssetsService";

export default function ViewData({ data, showAction, loading, fetchData }) {
  const [assetType, setAssetTypeName] = useState([]);
  const [brand, setBrandName] = useState([]);
  const [classification, setClassification] = useState([]);

  if (Array.isArray(brand) && brand.length > 0) {
    var brandList = [];
    brand.forEach((element) => {
      brandList.push(element.brandName);
    });
  }
  if (Array.isArray(classification) && classification.length > 0) {
    var classificationList = [];
    classification.forEach((element) => {
      classificationList.push(element.classificationName);
    });
  }
  if (Array.isArray(assetType) && assetType.length > 0) {
    var assetTypeList = [];
    assetType.forEach((element) => {
      assetTypeList.push(element.assetTypeName);
    });
  }

  const [loc, setLoc] = useState([]);
  const [status, setStatus] = useState([]);
  const ram = ["8GB", "16GB", "24GB", "32GB"];
  const storage = ["256GB", "500GB", "512GB", "1TB", "2TB"];
  const processor = ["i5", "i7", "m1", "m2"];
  const gen = ["6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th"];
  // const mac=["M1 chip","M2 chip"]

  const CustomDetailPanel = ({ row }) => {
    // console.log("prop received form customDetailPanel", row);

    const formattedDate = (timeStamp) => {
      const date = new Date(parseInt(timeStamp));
      //console.log(date, "date");
      const formattedDate = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      // console.log(formattedDate, "formattedDate");
      return formattedDate;
    };

    const detials = [
      { label: "Asset Type", value: row.original.assetTypeName || "-" },
      { label: "Asset Id", value: row.original.assetId || "-" },
      { label: "Asset Sl No.", value: row.original.assetSlNo || "-" },
      { label: "Brand", value: row.original.brand || "-" },
      { label: "Comments", value: row.original.comments || "-" },
      { label: "Assigned To", value: row.original.employeeName || "-" },
      { label: "State", value: row.original.state || "-" },
      { label: "Location", value: row.original.location || "-" },
      { label: "Assign Location", value: row.original.baseLocation || "-" },
      { label: "Return Location", value: row.original.returnLocation || "-" },
      { label: "User Location", value: row.original.userLocation || "-" },
      { label: "Generation", value: row.original.gen || "-" },
      { label: "Model", value: row.original.model || "-" },
      // { label: "OS", value: row.original.os || "-" },
      { label: "Processor", value: row.original.processor || "-" },
      { label: "RAM", value: row.original.ram || "-" },
      { label: "Storage", value: row.original.storage || "-" },
      {
        label: "Procure Date",
        value: row.original.procureDate
          ? formattedDate(row.original.procureDate)
          : "-",
      },
      {
        label: "Assign Date",
        value: row.original.assetStDate
          ? formattedDate(row.original.assetStDate)
          : "-",
      },
      {
        label: "Return Date",
        value: row.original.assetEndDate
          ? formattedDate(row.original.assetEndDate)
          : "-",
      },
      {
        label: "Warranty End Date",
        value: row.original.warrantyEndDate
          ? formattedDate(row.original.warrantyEndDate)
          : "-",
      },
    ];

    return (
      <Grid
        sx={{ bgcolor: "#F1F5FE", width: "100%" }}
        container
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
      >
        {detials.map((field, index) => {
          return (
            <Grid item xs={2} key={index}>
              <Stack>
                <Typography variant="h6" align="center">
                  {field.label}
                </Typography>
                <Typography variant="body1" align="center">
                  {field.value}
                </Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const inputSize = {
    style: {
      height: "5vh",
      maxHeight: "32px",
      width: "15em",
      padding: "2px 10px",
    },
    MenuProps: {
      PaperProps: {
        style: {
          maxHeight: 300, // Set your preferred max height
          overflowY: "auto", // Enable vertical scrolling
        },
      },
    },
  };

  // Custom cell component for actions
  const CustomActionCell = (props) => {
    // console.log("Props received by CustomActionCell:", props);
    const cell = props.cell.row.original;
    // console.log("Cell:", cell);

    const [updateForm, setUpdateForm] = useState({
      assetId: "",
      assetSlNo: "",
      assetType: "",
      model: "",
      baseLocation: "",
      comments: "",
      status: "",
      // statusID:"",
      ram: "",
      storage: "",
      gen: "",
      processor: "",
    });
    const [SuccessStatus, setSuccessStatus] = useState(false);
    const [FailedStatus, setFailedStatus] = useState(false);
    const [FailedMessage, setFailedMessage] = useState("Failed!!");
    const [snackBarStatus, setSnackBarStatus] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarSeverity, setSnackBarSeverity] = useState("success");
    const [updateModal, setUpdateModal] = useState(false);
    const [deleteModalStatus, setDeleteModalStatus] = useState(false);
    const [SelectedAssets, setSelectedAssets] = useState([]);

    // const [loc, setLoc] = useState([]);
    /// status from api
    const [selectedStatus, setSelectedStatus] = useState("");
    // const [status, setStatus] =useState([])

    //   const updateLocationStatus = async (value)=>{

    //     // debugger
    // console.log("called location")
    // console.log(updateForm)

    // let data={
    //   assetId:updateForm.assetId,
    //   statusId:updateForm.statusId,
    //   baseLocation:updateForm.baseLocation,

    // }

    // try {
    //   const response = await axios.put(`${baseUrl}/updateAssetStatusAndBaseLocation?assetId=${updateForm.assetId}&statusId=${updateForm.statusId}&baseLocation=${updateForm.baseLocation}&query=${value}`,data);
    //   // if (response) {
    //   //   handleClose();

    //   // }
    //   if (response.data.result === "success") {
    //     fetchData();
    //     setSuccessStatus(true);
    //     handleClose();
    //   }
    //   if (response.data.result === "error") {
    //     setFailedStatus(true);
    //     handleClose();
    //   }
    // } catch (error) {
    //   console.error(error);
    //   handleClose();
    //   setFailedStatus(true);
    // }

    //   }

    const currentUser = useSelector((state) => state.userReducer.userData);
    // console.log(currentUser,"userdetails")

    const handleDeleteMuiGrid = async () => {
      // debugger;
      try {
        let response = await deleteAsset(SelectedAssets);
        console.dir(response);
        if (response.status === 200) {
          fetchData();
          setDeleteModalStatus(false);
          setSelectedAssets([]);
          setSuccessStatus(true);
        }
        if (response.status !== 200) {
          setSelectedAssets([]);
          setDeleteModalStatus(false);
          setFailedStatus(true);
        }
      } catch (error) {
        setSelectedAssets([]);
        setDeleteModalStatus(false);
        setFailedStatus(true);
      }
    };

    const updateConfiguration = async () => {
      console.log("inside the function", updateForm);
      try {
        const response = await axios.put(
          `${baseUrl}/updateAssetConfiguration`,
          {
            assetId: updateForm.assetId,
            gen: updateForm.gen,
            processor: updateForm.processor,
            ram: updateForm.ram,
            storage: updateForm.storage,
            comments: updateForm.comments,
            performedBy: currentUser.employeeId,
          }
        );
        if (response.data.result === "success") {
          fetchData();
          setSuccessStatus(true);
          // setSnackBarMessage(response.data.message);
          // setSnackBarSeverity("success");
          // setSnackBarStatus(true);
          handleClose();
        }
        if (response.data.result === "error") {
          // setFailedStatus(true);
          setSnackBarMessage(response.data.message);
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
          handleClose();
        }
      } catch (error) {
        console.error(error);
        handleClose();
        setFailedStatus(true);
      }
    };

    const updateLocationStatus = async (value) => {
      console.log("called location");
      console.log(updateForm);
      const data = {
        performedBy: currentUser.employeeId,
      };

      try {
        const response = await axios.put(
          `${baseUrl}/updateAssetStatusAndBaseLocation?assetId=${updateForm.assetId}&statusId=${selectedStatus}&baseLocation=${updateForm.baseLocation}&query=${value}&userComments=${updateForm.comments}`,
          data
        );
        // if (response) {
        //   handleClose();

        // }
        // debugger;
        if (response.data.result === "success") {
          fetchData();
          setSuccessStatus(true);
          // setSnackBarMessage(response.data.message);
          // setSnackBarSeverity("success");
          // setSnackBarStatus(true);
          handleClose();
        }
        if (response.data.result === "error") {
          // setFailedStatus(true);
          setSnackBarMessage(response.data.message);
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
          handleClose();
        }
      } catch (error) {
        console.error(error);
        handleClose();
        setFailedStatus(true);
      }
    };

    const handleUpdateOnChange = (e) => {
      // debugger;
      let value = e.target.value;
      let name = e.target.name;
      const selectedStatus = status.find(
        (option) => option.statusName === value
      );
      const statusId = selectedStatus ? selectedStatus.statusId : null;
      let stateData = { ...updateForm };
      if (name === "status") {
        stateData[name] = value;
        setSelectedStatus(statusId);
      } else {
        stateData[name] = value;
      }
      setUpdateForm(stateData);
      console.log("971", updateForm);
    };

    let handleClose = (e) => {
      setDeleteModalStatus(false);
      setUpdateModal(false);
      setShowMenu(false);
      setSelectedStatus("");
      setActiveMenuItem("");

      setUpdateForm({
        assetId: "",
        assetSlNo: "",
        assetType: "",
        model: "",
        baseLocation: "",
        // statusId:"",
        status: "",
        ram: "",
        storage: "",
        gen: "",
        processor: "",
      });
      //setImportStatus(false);
    };

    const [showMenu, setShowMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeMenuItem, setActiveMenuItem] = useState("");
    // const [rowData, setRowData]= useState({})

    const handleMenuOpen = (event) => {
      // setRowData(rowData)
      setShowMenu(true);
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setShowMenu(false);
    };

    let ShowUpdateModal = (menuItem) => {
      console.log("rowData", cell);
      let func = () => {
        setUpdateForm({ ...updateForm, ...cell });
        setActiveMenuItem(menuItem);
        setUpdateModal(true);
        // setSelectedStatus(updateForm.status)
        console.log("23", activeMenuItem, menuItem, updateForm);
      };
      func();
    };

    // useEffect(()=>{
    //   console.log("12",updateForm.assetId)
    //   console.log("123",updateForm.status)
    //   // setSelectedStatus(updateForm.status)
    //   console.log("1",selectedStatus)
    // },[updateForm])

    const handleDeleteClick = () => {
      console.log("rowData", cell);

      console.log("Delete clicked for", cell.assetId);
      setDeleteModalStatus(true);
      setSelectedAssets([cell.assetId]);

      // Handle delete action for the selected row
    };

    //update validation

    const handleUpdateValidations = (val) => {
      // debugger
      if (val === 1) {
        console.log("updateForm data", updateForm);
        if (updateForm.baseLocation === null) {
          setFailedStatus(true);
          setFailedMessage("Base Location cannot be empty");
          // alert("Base Location cannot be empty");
        } else updateLocationStatus("locationUpdate");
      } else if (val === 2) {
        console.log("updateForm data 2", updateForm);
        if (updateForm.status === null || updateForm.status === "") {
          setFailedStatus(true);
          setFailedMessage("Status cannot be empty");
          // alert("Status cannot be empty");
        } else updateLocationStatus("statusUpdate");
      } else if (val === 3) {
        console.log("updateForm data 3", updateForm);

        updateConfiguration();
      }
    };

    if (SuccessStatus | FailedStatus) {
      setTimeout(() => {
        setFailedStatus(false);
        setSuccessStatus(false);
      }, 3000);
    }

    useEffect(() => {
      if (activeMenuItem === "updateStatus") {
        const selectedStatus = status.find(
          (option) => option.statusName === updateForm.status
        );
        const statusId = selectedStatus ? selectedStatus.statusId : null;
        setSelectedStatus(statusId);
      }
    }, [activeMenuItem]);

    return (
      <>
        <Box>
          <Button onClick={handleMenuOpen}>
            <MoreVertIcon />
          </Button>
          <Menu anchorEl={anchorEl} open={showMenu} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                ShowUpdateModal("updateLocation");
              }}
              selected={activeMenuItem === "updateLocation"}
              disabled={cell.isAssigned === true}
            >
              Update Location
            </MenuItem>

            <MenuItem
              onClick={() => {
                ShowUpdateModal("updateStatus");
              }}
              selected={activeMenuItem === "updateStatus"}
              // disabled={cell.isAssigned === true}
            >
              Update Status
            </MenuItem>

            <MenuItem
              onClick={() => {
                ShowUpdateModal("updateConfiguration");
              }}
              selected={activeMenuItem === "updateConfiguration"}
              disabled={cell.assetTypeName !== "Laptop" && true}
            >
              Update Configuration
            </MenuItem>

            <MenuItem
              onClick={handleDeleteClick}
              selected={activeMenuItem === "Delete"}
            >
              Delete
            </MenuItem>
            {/* <MenuItem onClick={ShowEditModalFuncNew}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteClick}>Delete</MenuItem> */}
          </Menu>
        </Box>

        {/* 1327 */}

        {/* modal update */}

        <Dialog open={updateModal} sx={{}}>
          <DialogContent sx={{ padding: "25px" }} className="wbScroll">
            <Stack
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                spacing={2}
                rowSpacing={3}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {/* location drop down */}

                {activeMenuItem === "updateLocation" && (
                  <>
                    <Grid item xs={6} sx={{ maxWidth: "100%" }}>
                      <Typography sx={{ color: "#424242", width: "100%" }}>
                        Base Location<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          select
                          // disabled={false}
                          disabled={activeMenuItem === "updateStatus"}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          SelectProps={inputSize}
                          // InputProps={inputSize}

                          id="outlined-basic"
                          variant="outlined"
                          name="baseLocation"
                          value={updateForm.baseLocation}
                          onChange={handleUpdateOnChange}
                        >
                          {loc.map((option) => {
                            return (
                              <MenuItem key={option.label} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ color: "#424242" }}>
                        Comments
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          // disabled={true}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          // SelectProps={inputSize}
                          InputProps={inputSize}
                          id="outlined-basic"
                          variant="outlined"
                          name="comments"
                          value={updateForm.comments}
                          //  value={selectedStatus}
                          onChange={handleUpdateOnChange}
                        ></TextField>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {activeMenuItem === "updateStatus" && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={{ color: "#424242" }}>
                        Status <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          select
                          disabled={activeMenuItem === "updateLocation"}
                          // disabled={true}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          SelectProps={inputSize}
                          // InputProps={inputSize}

                          id="outlined-basic"
                          variant="outlined"
                          name="status"
                          value={updateForm.status}
                          //  value={selectedStatus}
                          onChange={handleUpdateOnChange}
                        >
                          {status
                            .filter((item) => item.isActive === true)
                            .map((option) => {
                              return (
                                <MenuItem
                                  key={option.statusId}
                                  value={option.statusName}
                                >
                                  {option.statusName}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ color: "#424242" }}>
                        Comments
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          // disabled={true}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          // SelectProps={inputSize}
                          InputProps={inputSize}
                          id="outlined-basic"
                          variant="outlined"
                          name="comments"
                          value={updateForm.comments}
                          //  value={selectedStatus}
                          onChange={handleUpdateOnChange}
                        ></TextField>
                      </FormControl>
                    </Grid>
                  </>
                )}

                {activeMenuItem === "updateConfiguration" && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={{ color: "#424242" }}>
                        RAM <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          select
                          // disabled={activeMenuItem === "updateLocation"}
                          // disabled={false}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          SelectProps={inputSize}
                          // InputProps={inputSize}

                          id="outlined-basic"
                          variant="outlined"
                          name="ram"
                          value={updateForm.ram}
                          //  value={selectedStatus}
                          onChange={handleUpdateOnChange}
                        >
                          {ram.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography sx={{ color: "#424242" }}>
                        Storage <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          select
                          // disabled={activeMenuItem === "updateLocation"}
                          //  disabled={false}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          SelectProps={inputSize}
                          // InputProps={inputSize}

                          id="outlined-basic"
                          variant="outlined"
                          name="storage"
                          value={updateForm.storage}
                          //  value={selectedStatus}
                          onChange={handleUpdateOnChange}
                        >
                          {storage.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ color: "#424242" }}>
                        Generation <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          select
                          // disabled={activeMenuItem === "updateLocation"}
                          //  disabled={false}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          SelectProps={inputSize}
                          // InputProps={inputSize}

                          id="outlined-basic"
                          variant="outlined"
                          name="gen"
                          value={updateForm.gen}
                          //  value={selectedStatus}
                          onChange={handleUpdateOnChange}
                        >
                          {gen.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ color: "#424242" }}>
                        Processor <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          select
                          // disabled={activeMenuItem === "updateLocation"}
                          //  disabled={false}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          SelectProps={inputSize}
                          // InputProps={inputSize}

                          id="outlined-basic"
                          variant="outlined"
                          name="processor"
                          value={updateForm.processor}
                          //  value={selectedStatus}
                          onChange={handleUpdateOnChange}
                        >
                          {processor.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ color: "#424242" }}>
                        Comments
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          // disabled={true}
                          // sx={{ width: "100%", }}
                          margin="normal"
                          // SelectProps={inputSize}
                          InputProps={inputSize}
                          id="outlined-basic"
                          variant="outlined"
                          name="comments"
                          value={updateForm.comments}
                          //  value={selectedStatus}
                          onChange={handleUpdateOnChange}
                        ></TextField>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (activeMenuItem === "updateLocation")
                  handleUpdateValidations(1);
                else if (activeMenuItem === "updateStatus")
                  handleUpdateValidations(2);
                else if (activeMenuItem === "updateConfiguration")
                  handleUpdateValidations(3);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* *modal DELETE***  */}

        <Dialog open={deleteModalStatus}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <ErrorOutlineIcon sx={{ color: "#FF9A22", fontSize: "2.3rem" }} />
            <Typography variant="h6">Are you sure?</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>
              <span>Are you sure you want to delete this record?</span>
              <br />
              <span>This process cannot be undone.</span>
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={() => handleDeleteMuiGrid()}
              variant="contained"
              sx={{
                backgroundColor: "#CF0100",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#B70100",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                if (SelectedAssets.length === 1) {
                  setSelectedAssets([]);
                }
                setDeleteModalStatus(false);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/***** SNACKBAR COMPONENT * */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={SuccessStatus}
          autoHideDuration={6000}
          onClose={handleClose}
          style={{ marginTop: "60px" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            SUCCESS!!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={FailedStatus}
          autoHideDuration={6000}
          onClose={handleClose}
          style={{ marginTop: "60px" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {FailedMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ marginTop: "60px" }}
          open={snackBarStatus}
          autoHideDuration={4000}
          onClose={() => setSnackBarStatus(false)}
        >
          <Alert
            onClose={() => setSnackBarStatus(false)}
            severity={snackBarSeverity}
            sx={{ width: "100%" }}
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>

        {/* *******************  */}

        {/* end */}
      </>
    );
  };

  const initialState = {
    sorting: [
      {
        id: "assetId", //sort by age by default on page load
        desc: false,
      },
    ],
    density: "compact", // Set default density to compact
    // enableDensityToggle: false, // Disable density toggle
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "assetId",
        header: "Asset ID",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      // {
      //   accessorKey: "brand",
      //   header: "Brand",
      //   size: 100,
      // },
      {
        accessorKey: "assetSlNo",
        header: "Asset Serial No",
        size: 180,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "assetTypeName",
        header: "Asset Type",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        filterVariant: "multi-select",
        filterSelectOptions: assetTypeList,
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "classificationName",
        header: "Classification",
        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        filterVariant: "multi-select",
        filterSelectOptions: classificationList,
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "brand",
        header: "Brand",
        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        filterVariant: "multi-select",
        filterSelectOptions: brandList,
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "location",
        header: "Location",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },

      {
        accessorKey: "isAssigned",
        header: "Assigned",
        size: 120,
        // Cell: ({ value }) => <span>{value ? "✔" : "❌"}</span>, // Custom cell rendering for the isActive column
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          // console.log("value of isActive", cell.row.original.isActive);
          const value = cell.row.original.isAssigned;
          return (
            <span>
              {value ? (
                <Done sx={{ color: "green" }} />
              ) : (
                <Close sx={{ color: "red" }} />
              )}
            </span>
          );
        }, // Custom cell rendering for the isActive column
      },
      {
        accessorKey: "status",
        header: "Current Status",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,

                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "assetStDate",
        header: "Assign Date",
        size: 100,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          if (cell.row.original.assetStDate === null) {
            return "-";
          } else {
            const date = new Date(parseInt(cell.row.original.assetStDate));
            const formattedDate = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
            return formattedDate;
          }
        },
      },
      {
        accessorKey: "assetEndDate",
        header: "Return Date",
        size: 100,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          if (cell.row.original.assetEndDate === null) {
            return "-";
          } else {
            const date = new Date(parseInt(cell.row.original.assetEndDate));
            const formattedDate = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
            return formattedDate;
          }
        },
      },
      {
        accessorKey: "employeeName",
        header: "Assigned To",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "userStatus",
        header: "Employee Status",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      showAction
        ? {
            accessorKey: "action",
            header: "Action",
            sortable: false,
            filterable: false,
            enableColumnActions: false,

            size: 120,
            // Cell: ({ cell }) =>
            //   status.length > 0 ? <CustomActionCell cell={cell} /> : null,

            Cell: ({ cell }) => <CustomActionCell cell={cell} />,
          }
        : undefined,
    ],
    [showAction, status, loc]
  );

  const table = useMaterialReactTable({
    data,
    columns: columns.filter(Boolean),
    // expandedRows,
    renderDetailPanel: CustomDetailPanel,
    state: { showSkeletons: loading },
    initialState: initialState,
    defaultColumn: { minSize: 20, maxSize: 400, size: 80 },
    muiTableHeadCellProps: {
      sx: {
        padding: "10px",
        background: "#F1F5FE",
        // whiteSpace:"normal"
      },
    },
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
        // width: '100%'
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor:
          !row.getIsExpanded() &&
          (row.original.status === "not returned" ||
            row.original.status === "NOT RETURNED")
            ? "#f8d2b9"
            : "inherit",
      },
    }),
    enableDensityToggle: false,
    enableStickyHeader: true,
  });

  useEffect(() => {
    const getStatus = async () => {
      // debugger
      try {
        // let data = await axios.get(`${baseUrl}/getStatus`);
        let data = await axios.get(`${baseUrl}/getAllStatuses`);
        console.log(data, "data");
        // console.log(data.data.data, "data1");
        if (data) {
          setStatus(data.data.data);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    const getLocation = async () => {
      try {
        let data = await axios.get(
          "https://topas-backend.cherrywork.com/asset-api/getTopasLocations"
        );
        // console.log(data, "data");
        // console.log(data.data.data, "data1");
        if (data) {
          setLoc(data.data.data);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    const getAssetTypeName = async () => {
      // debugger;
      try {
        let res = await axios.get(`${baseUrl}/allAssetTypes`);
        // let res = await axios.get("http://localhost:8080/getAssetTypeName");
        console.log(res);
        if (res.data.result === "success") {
          console.log("assetTypeName", res.data.data);
          setAssetTypeName(res.data.data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const getAllBrands = async () => {
      try {
        const response = await axios.get(`${baseUrl}/allBrands`);
        console.log("brand name", response.data);
        if (response.data.result === "success") {
          setBrandName(response.data.data);
        }
      } catch (error) {
        console.log(error.message);
        //  setLoading(false)
      }
    };
    const getAllClassification = async () => {
      try {
        const response = await axios.get(`${baseUrl}/allClassifications`);
        console.log("classification name", response.data);
        if (response.data.result === "success") {
          setClassification(response.data.data);
        }
      } catch (error) {
        console.log(error.message);
        //  setLoading(false)
      }
    };

    // console.log(loc, "location12");
    getAssetTypeName();
    getAllBrands();
    getAllClassification();
    getLocation();
    getStatus();
  }, []);
  useEffect(() => {
    console.log("status", status);
    // console.log("location",loc)
  }, [status, loc]);

  return <MaterialReactTable table={table} />;
}
