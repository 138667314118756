import React from "react";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../Utils/serviceRequest";
import { useMemo } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

function ShowData({
  data,
  returnLocation,
  userLocation,
  assetHandOverDate,
  assetReturnDate,
  isLoading,
}) {
  // console.log("show Data", data)
  // const [data,setData]= useState([])

  const CustomDetailPanel = ({ row }) => {
    // console.log("prop received form customDetailPanel", row);
    const history = row.original.historyData
      ? [...row.original.historyData]
      : [];

    // console.log("123456", history);
    // console.log("data",data)

    return (
      <Grid container>
        <Grid item xs={4}>
          <Box sx={{ padding: "1em" }}>
            <Typography
              sx={{ color: "#194E8C", fontSize: "17px", fontWeight: 600 }}
            >
              Asset History
            </Typography>
            <Divider />
            <Stepper orientation="vertical" activeStep={history.length}>
              {history
                ?.sort((a, b) => b.createdOn - a.createdOn)
                .map((item) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Step expanded={true}>
                      <StepLabel>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {/* {item.progressStatus === 'Demand Created'
                              ? `${item.progressStatus} (Pending- TPD)`
                              : item.progressStatus} */}
                          {item.action}
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Box>
                          {/* <Typography>{item.description}</Typography> */}
                          <Typography sx={{ fontSize: "0.8em" }}>
                            Performed By :- {item.performedBy}
                          </Typography>
                          <Typography sx={{ fontSize: "0.8em" }}>
                            Comments :- {item.comments}
                          </Typography>
                          <Typography sx={{ fontSize: "0.8em" }}>
                            Date :- {DateString(item.createdOn)}
                          </Typography>
                        </Box>
                      </StepContent>
                    </Step>
                  );
                })}
            </Stepper>
          </Box>

          {/* <Stack
                direction={'column'}
                sx={{ width: '100%', height: '100%' }}
                justifyContent={'center'}
                alignItems={'center'}>
                
              </Stack> */}
        </Grid>
        {/* <Grid item xs={8}>
            <Box sx={{ paddingTop: '1em' }}>
              <Typography sx={{ color: '#194E8C', fontSize: '17px', fontWeight: 600 }}>
                Demand Details
              </Typography>
              <Divider  />
            </Box>
          </Grid> */}
      </Grid>
    );
  };
  const initialState = {
    sorting: [
      {
        id: "assetId",
        desc: false,
      },
    ],
    density: "compact", // Set default density to compact
    columnVisibility: { baseLocation: false, returnLocation: false },
    // enableDensityToggle: false, // Disable density toggle
  };
  const DateString = (timestamp) => {
    // if (timestamp === null || timestamp === undefined) {
    //   return "-";
    // }
    // var time = Number(timestamp);
    // var date = new Date(time).toISOString().substring(0, 10);
    // return date;

    if (timestamp !== null) {
      // var time = Number(timestamp);
      var time = Number(timestamp);
      var date = new Date(time + 5.5 * 60 * 60 * 1000).toLocaleDateString(
        "en-GB"
      );
      return date;
    }
    return "-";
  };

  // Define column configuration
  const columns = useMemo(
    () => [
      {
        accessorKey: "assetType",
        header: "Asset Type",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "assetId",
        header: "Asset ID",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },

      {
        accessorKey: "assetSlNo",
        header: "Asset Serial No",
        size: 180,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "assignedTo",
        // accessorKey: "model",
        header: "Assigned To",
        size: 130,
        // enableGlobalFilter: true,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          var value = cell.getValue();
          if (value === null) value = "";
          // console.log("assigned to :", value);
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {value}
            </span>
          );
        },
      },

      {
        accessorKey: "assetStDate",
        header: "Assign Date",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "procureDate",
        header: "Procure Date",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "currentStatus",
        header: "Current Status",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },

      // {
      //   accessorKey: "assignedBy",
      //   header: "Assigned By",
      //   size: 130,
      //   Header: ({ column }) => (
      //     <span style={{ whiteSpace: "normal" }}>
      //       {column.columnDef.header}
      //     </span>
      //   ),
      //   Cell: ({ cell }) => {
      //     return (
      //       <span
      //         style={{
      //           fontSize: "13px",
      //           fontWeight: 400,
      //           color: "#757575",
      //           whiteSpace: "normal",
      //         }}
      //       >
      //         {cell.getValue()}
      //       </span>
      //     );
      //   },
      // },
      // {
      //   accessorKey: "returnedBy",
      //   header: "Returned To",
      //   size: 120,
      //   Header: ({ column }) => (
      //     <span style={{ whiteSpace: "normal" }}>
      //       {column.columnDef.header}
      //     </span>
      //   ),
      //   Cell: ({ cell }) => {
      //     return (
      //       <span
      //         style={{
      //           fontSize: "13px",
      //           fontWeight: 400,
      //           color: "#757575",
      //           whiteSpace: "normal",
      //         }}
      //       >
      //         {cell.getValue()}
      //       </span>
      //     );
      //   },
      // },
      {
        accessorKey: "location",
        header: "Current Location",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "baseLocation",
        header: "Base Location",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },

      {
        accessorKey: "returnLocation",
        header: "Return Location",
        size: 120,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
    ],
    []
  );

  // Create table instance
  const table = useMaterialReactTable({
    data,
    // assignData,
    // columns,
    columns: columns.filter(Boolean), // Remove undefined elements from the columns array
    renderDetailPanel: CustomDetailPanel,
    state: { showSkeletons: isLoading },

    initialState: initialState,
    defaultColumn: { minSize: 20, maxSize: 400, size: 80 },
    muiTableHeadCellProps: {
      sx: {
        padding: "10px",
        background: "#F1F5FE",
        // whiteSpace:"normal"
      },
    },
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
        // width: '100%'
      },
    },
    enableDensityToggle: false,
    enableStickyHeader: true,
    // state: { showSkeletons: isLoading },
  });

  return (
    <>
      <MaterialReactTable table={table} />;
    </>
  );
}

export default ShowData;
